<template>
  <b-row />
</template>

<script>
import ComunesService from "@/app/core/api/comunes";
import AlertsService from "@/app/shared/services/alerts.service";

export default {
  mounted() {
    const body = {
      identificacion: this.$store.getters.identificacion,
      accessToken: this.$store.getters.token,
      idAcceso: this.$store.getters.idAcceso,
    };

    ComunesService.logout(body)
      .then(() => {
        this.$store.dispatch("logout");
        AlertsService.success("Salida exitosa!", "Te has deslogueado exitosamente!");
      })
      .catch(() => {
        AlertsService.error("Error", "Fallo al cerrar la sesión, vuelve a intentarlo nuevamente.");
        this.$router.push("/home");
      });
  },
};
</script>
